import * as React from 'react';
import { styled } from '@mui/material/styles';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardMedia from '@mui/material/CardMedia';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import Collapse from '@mui/material/Collapse';
import Avatar from '@mui/material/Avatar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import { red } from '@mui/material/colors';
import FavoriteIcon from '@mui/icons-material/Favorite';
import ShareIcon from '@mui/icons-material/Share';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import MMCards from '../data/cards.json';
import { Button } from '@mui/material';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import BulletedList from './List';

const ExpandMore = styled((props) => {
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})(({ theme, expand }) => ({
  transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
  marginLeft: 'auto',
  transition: theme.transitions.create('transform', {
    duration: theme.transitions.duration.shortest,
  }),
}));

export default function MMCard({ theme, setRandom, cardId }) {
  const [expanded, setExpanded] = React.useState(false);

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  const notifyCopy = () => toast.success('Link to this card copied!', {
    position: "bottom-center",
    autoClose: 1000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
  });

  const cardToShow = MMCards.find(x => x.id === cardId);

  if (!cardToShow){
    return "Error"
  }

  const colorScheme = {
    color: '#faf3ddff',
    backgroundColor: cardToShow.color === 'green' ? '#73956fff' : '#66c7f4ff',
  }


  return (
    <>
    <ToastContainer
      position="bottom-center"
      autoClose={1000}
      hideProgressBar={false}
      newestOnTop={false}
      closeOnClick
      rtl={false}
      pauseOnFocusLoss
      draggable
      pauseOnHover
    />
     { cardToShow ? 
      <Card style={colorScheme} sx={{ maxWidth: 345 }}>
        <CardHeader
          // avatar={
          //   <Avatar sx={{ bgcolor: '#2d3142ff' }} aria-label="Author">
          //     MM
          //   </Avatar>
          // }
          action={
            <IconButton aria-label="settings">
              <MoreVertIcon />
            </IconButton>
          }
          titleTypographyProps={{ variant:'h4' }}
          title={cardToShow.title}
          subheader={cardToShow.subheader}
        />
        <CardMedia
          component="img"
          height="194"
          image={`/images/${cardToShow.image}`}
          alt={cardToShow.image_desc}
        />
        <Typography 
          variant="subtitle2" 
          style={{ fontStyle: 'italic', fontSize: 10, marginLeft: 5 }}
        >
          {`📷: ${cardToShow.image_attr}`}
        </Typography>
        <CardContent>
          <Typography variant="body2" color="text.secondary">
            {cardToShow.description}
          </Typography>
        </CardContent>
        <CardActions disableSpacing>
          {/* <IconButton aria-label="add to favorites">
            <FavoriteIcon />
          </IconButton> */}
          <IconButton aria-label="share">
            <CopyToClipboard text={window.location.href}
              onCopy={() => notifyCopy()}>
              {/* <ShareIcon /> */}
              <ContentCopyIcon/>
            </CopyToClipboard>
          </IconButton>
          <ExpandMore
            expand={expanded}
            onClick={handleExpandClick}
            aria-expanded={expanded}
            aria-label="show more"
          >
            <ExpandMoreIcon />
          </ExpandMore>
        </CardActions>
        <Collapse in={expanded} timeout="auto" unmountOnExit>
          <CardContent>
            <Typography variant="h5">Focus Points:</Typography>
            <hr/>
            <Typography variant="h6">{cardToShow.heading_1}</Typography>
            <BulletedList items={cardToShow.list_1}/>

            <Typography variant="h6">{cardToShow.heading_2}</Typography>
            <BulletedList items={cardToShow.list_2}/>

            <Typography variant="h6">{cardToShow.heading_3}</Typography>
            <BulletedList items={cardToShow.list_3}/>
            
            <Typography>
            {cardToShow.long_text}
            </Typography>
          </CardContent>
        </Collapse>
      </Card>
      : 'Error occured accessing this card.'
      }
      <br/>
      <Button 
        onClick={() => setRandom(theme, cardToShow.id)}
        variant="text"
        style={{ ...colorScheme, outline: 'solid 1px #444545', textTransform: 'none', fontFamily: "kreon" }}
      >
        New Random Card
      </Button>
      <br/>
      {/* <Button 
        onClick={() => console.log('todo')}
        variant="text"
        style={{ ...colorScheme, outline: 'solid 1px #444545', textTransform: 'none', fontFamily: "kreon" }}
      >
        Browse All Cards of This Color
      </Button> */}
    </>
  );
}