import { AppBar, Menu, MenuItem, Stack } from '@mui/material';
import React from 'react';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import { Box } from '@mui/system';
import { useNavigate } from "react-router-dom";

const SkillsAppBar = ({ theme }) => {
    let navigate = useNavigate();
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };  
    const handleClose = () => {
       setAnchorEl(null);
    };
    const handleMenuClick = (color) => {
        navigate(`/${color}`)
    }

    return (
        <Box sx={{ flexGrow: 1 }}>
            <AppBar position="static">
                <Toolbar>
                    <Typography onClick={()=> navigate('/')} variant="h6" component="div" sx={{ flexGrow: 1 }}>
                        Mind To Movement
                    </Typography>
                    
                    <Stack spacing={2} direction="row">
                        <Button onClick={() => handleMenuClick('green', 'Green')} variant="text" style={{ outline: 'solid 1px #444545', textTransform: 'none', fontFamily: "kreon", color: '#444545', backgroundColor: '#73956fff' }}>Technique & Movement</Button>
                        <Button onClick={() => handleMenuClick('blue', 'Blue')} variant="text" style={{ outline: 'solid 1px #444545', textTransform: 'none', fontFamily: "kreon", color: '#444545', backgroundColor: '#66c7f4ff' }}>Mental Game & Focus</Button>
                    </Stack>

                    {/* <IconButton
                        size="large"
                        edge="start"
                        color="inherit"
                        aria-label="menu"
                        sx={{ mr: 2 }}
                    >
                        <MenuIcon
                            id="basic-button"
                            aria-controls={open ? 'basic-menu' : undefined}
                            aria-haspopup="true"
                            aria-expanded={open ? 'true' : undefined}
                            onClick={handleClick}
                        />
                        <Menu
                            id="basic-menu"
                            anchorEl={anchorEl}
                            open={open}
                            onClose={handleClose}
                            MenuListProps={{
                            'aria-labelledby': 'menu-div',
                            }}
                        >
                            <MenuItem onClick={() => handleMenuClick('green')}>{`Technique & Movement`}</MenuItem>
                            <MenuItem onClick={() => handleMenuClick('blue')}>{`Mental Game & Focus`}</MenuItem>
                        </Menu>
                    </IconButton> */}
                </Toolbar>
            </AppBar>
        </Box>
    );
}
 
export default SkillsAppBar;