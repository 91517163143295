import React from 'react';
import Typography from '@mui/material/Typography';
import { Box } from '@mui/system';
import CenterGrid from './Grid';
import { Button, Container, Grid } from '@material-ui/core';
import MenuIcon from '@mui/icons-material/Menu';
import { Stack } from '@mui/material';


const LandingPage = ({ setPage }) => {
    const content = () => {
        return (
            <Box sx={{ flexGrow: 1, padding: 2 }} >
                <Typography variant="h3" color="text.Primary">Mind To Movement</Typography>
                <hr style={{ borderColor : '#444545' }}/>
                {/* <Container>
                    <Stack spacing={2} direction="row">
                        <Button className="" variant="text" style={{ borderRadius: 5, fontSize: 18, padding: 10, fontFamily: "kreon", border: "none", backgroundColor: '#73956fff' }}>Technique & Movement</Button>
                        <Button className="" variant="text" style={{ borderRadius: 5, fontSize: 18, padding: 10, fontFamily: "kreon", border: "none", backgroundColor: '#66c7f4ff' }}>Mental Game & Focus</Button>
                    </Stack>
                    <br/>
                </Container> */}

                <Typography variant="h5" color="text.secondary">What is Mind To Movement?</Typography>
                <Typography variant="body2" color="text.secondary">
                   "Mind To Movement" is a very simple app that is intended to help climbers move and learn intentionally.
                   It essentially consists of a bunch of cards in several categories that will help the intentional climber focus on learning something every session.

                   These categories are: 
                    <ul>
                        <li>
                            {`Technique & Movement,`}
                        </li>
                        <li>
                            {`Mental Game & Focus`}
                        </li>
                    </ul>
                    {/* Select From <MenuIcon/> */}
                </Typography>
                <br/>
                <Typography variant="h5" color="text.secondary">How can I use it?</Typography>
                <Typography variant="body2" color="text.secondary">
                    Select a category and either pick a random card or browse the existing cards. 
                    Once selected, keep that card and the tips it has on your mind while warming up or doing mileage climbing.
                    This can obviously be done during whatever the hell climbing you want. However, I have found that I learn best when warming up and when the 'ego's percieved stakes' are low.

                    Keep this page pulled up on your phone and use the cards to remind yourself what you are trying to practice!
                </Typography>
                <br/>
                <Typography variant="h5" color="text.secondary">Who needs this? Who cares?</Typography>
                <Typography variant="body2" color="text.secondary">
                   I'm sure we've all heard something to the effect of 'You cant have too much power' or 'Your fingers can never be strong enough'. But what about when someone weaker than you learns a move faster? 
                   (if you've climbed long enough, you've probably experienced this). Learning movement is a life long journey and we can be very lazy about it.
                   We have finger-boarding plans, training plans etc etc. Why no 'movement schema' growing plans?
                   For almost everyone growing your vocabulary, or quiver, of known movement schemas is vital to improving as a climber.
                </Typography>
                <br/>
                <Typography variant="h5" color="text.secondary">Why we have found it useful?</Typography>
                <Typography variant="body2" color="text.secondary">
                    I started this thing on index cards I kept in my training journal. After, months of doign it EVERY warmup. I really felt I was learning more.

                    Here are a few brief reasons 
                    <ul>
                        <li>
                            {`External accountability. You tell yourself you'll practice climbing more powerfully. How often do you really follow through?`}
                        </li>
                        <li>
                            {`Randomization of focus. We've all got strengths and we love to OVER work them and UNDER work weaknesses.`}
                        </li>
                        <li>
                            {`A consistent method for learning... EVERY single time you go climbing.`}
                        </li>
                    </ul>
                </Typography>
                <br/>
            </Box>
        );
    }
    return (
        <CenterGrid content={content()} />
    );
}

export default LandingPage;