import { useState } from 'react';
import './App.css';
import { createTheme } from '@material-ui/core/styles';
import { ThemeProvider } from '@mui/material/styles';
import "typeface-kreon";
import {
  BrowserRouter as Router,
  Routes,
  Route,
} from "react-router-dom";
import Page from './components/Page';

const GreenTheme = createTheme({
  palette: {
    primary: {
      main: '#73956fff',
    },
    secondary: {
      main: '#faf3ddff',
    },
    background: {
      default: '#FAF8D4',
    },
  },
  typography: {
    useNextVariants: true,
    fontFamily: 'kreon',
  },
});

const BlueTheme = createTheme({
  palette: {
    primary: {
      main: '#66c7f4ff',
    },
    secondary: {
      main: '#faf3ddff',
    },
    background: {
      default: '#FAF8D4',
    },
  },
  typography: {
    useNextVariants: true,
    fontFamily: 'kreon',
  }
});


const LandingTheme = createTheme({
  palette: {
    primary: {
      main: '#2d3142ff',
    },
    secondary: {
      main: '#faf3ddff',
    },
    background: {
      default: '#FAF8D4',
    },
  },
  typography: {
    useNextVariants: true,
    fontFamily: 'kreon',
  }
});

function themeSwitch (string) {
  switch (string) {
    case 'green':
      return GreenTheme;
    case 'blue':
      return BlueTheme;
    case 'landing':
      return LandingTheme;
    default:
      break;
  }
}

function App() {
  const [theme, setTheme] = useState(themeSwitch('landing'));
 
  return (
    <Router>
      <ThemeProvider theme={theme}>
      <Routes>
        <Route path="/" element={<Page  theme={'landing'} setTheme={(t) => setTheme(themeSwitch(t))}/>}/>
        <Route path="/green" element={<Page  theme={'green'} setTheme={(t) => setTheme(themeSwitch(t))}/>}/>
        <Route path="/blue" element={<Page  theme={'blue'} setTheme={(t) => setTheme(themeSwitch(t))}/>}/>
        <Route path="/green/:id" element={<Page  theme={'green'} setTheme={(t) => setTheme(themeSwitch(t))}/>}/>
        <Route path="/blue/:id" element={<Page  theme={'blue'} setTheme={(t) => setTheme(themeSwitch(t))}/>}/>
      </Routes>
      </ThemeProvider>
    </Router>
  );
}

export default App;
