import React from 'react';
import Typography from '@material-ui/core/Typography';

const BulletedList = ({ items }) => {
  return (
    <ul>
      {items.map((item, index) => (
        <li style={{ fontFamily: "kreon", fontWeight: 'lighter', marginBottom: 5}} key={index}>
            {/* <Typography> */}
              {item}
            {/* </Typography> */}
        </li>
      ))}
    </ul>
  );
};

export default BulletedList;
