import React from 'react';
import { Grid, Stack, Typography } from '@mui/material';
import InstagramIcon from '@mui/icons-material/Instagram';
import EmailIcon from '@mui/icons-material/Email';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const Footer = () => {
    const EMAIL = 'mindtomovement.climb@gmail.com';
    const notifyCopy = () => toast.success('Email address copied!', {
        position: "bottom-center",
        autoClose: 1000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
    });
    return (
        <footer style ={{
            position:"relative",
            bottom:0,
            paddingRight: 10,
            paddingLeft: 10
        }}>
            <ToastContainer
                position="bottom-center"
                autoClose={1000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
            />
            <Grid
                container
                spacing={0}
                direction="column"
                alignItems="center"
            >
                <Stack spacing={3} direction="row">
                    <CopyToClipboard text={EMAIL} onCopy={() => notifyCopy()}>
                        <Typography>
                            Contact Me: <i style={{ cursor: 'pointer' }}>{EMAIL}</i>
                        </Typography>
                    </CopyToClipboard>
                    <CopyToClipboard text={EMAIL} onCopy={() => notifyCopy()}>
                        <EmailIcon style={{ cursor: 'pointer' }} />
                    </CopyToClipboard>
                    <a href="https://www.instagram.com/mind.to.movement/" target="_blank" rel="noreferrer">
                        <InstagramIcon />
                    </a>
                </Stack>
            </Grid>
        </footer>
    );
}
 
export default Footer;