import Grid from '@material-ui/core/Grid';

export default function CenterGrid({content}) {
    return ( 
        <Grid
            container
            spacing={0}
            direction="column"
            alignItems="center"
            // justify="center"
            style={{ marginTop: '5vh', minHeight: '100vh' }}
        >
            {/* <Grid item xs={12}> */}
                {content}
            {/* </Grid>       */}
        </Grid>
    );
}