import { Box } from '@mui/system';
import React, { useEffect } from 'react';
import MMCard from './MMCard';
import CenterGrid from './Grid';
import LandingPage from './LandingPage';
import SkillsAppBar from './SkillsAppBar';
import MMCards from '../data/cards.json';
import { useLocation } from "react-router-dom"
import { useParams } from 'react-router-dom';
import { useNavigate } from "react-router-dom";
import Footer from './Footer';

const Page = ({ theme, setTheme }) => {

    const location = useLocation();
    const { id } = useParams();
    let navigate = useNavigate();

    const randomCard = (color, dissallow) => {
        const cardsOfColor = [...MMCards].filter(card => card.color === color);
        const newCardID = cardsOfColor[Math.floor(Math.random()*cardsOfColor.length)].id;
        if (newCardID === dissallow) {
            return randomCard(color, dissallow);
        }
        return newCardID;
    };

    const setRandomCardOfColor = (color, dissallow) => {
        navigate(`/${theme}/${randomCard(color, dissallow)}`)
    };


    const setCurrentCardForUrl = (id) => {
        let exists;
        if (location.pathname.includes('/green')) {
            exists = MMCards.find((card) => card.id === id && card.color === 'green')
        } else if (location.pathname.includes('/blue'))  {
            exists = MMCards.find((card) => card.id === id && card.color === 'blue')
        }
        if (!exists) {
            alert("That card did not exist. Giving you a random one instead.")
            navigate('/green/1')
        }
    }

    useEffect(() => {
      if (id) {
        setCurrentCardForUrl(parseInt(id));
      } else if (theme !== 'landing') {
          setRandomCardOfColor(theme)
      }
    }, [id])

    useEffect(() => {
        if (location.pathname === '/') {
            setTheme('landing')
        } else if (location.pathname.includes('/green')) {
            setTheme('green')
        } else if (location.pathname.includes('/blue'))  {
            setTheme('blue')
        }
    }, [location]);

    useEffect(() => {
        if (theme !== 'landing' && !id) {
            setRandomCardOfColor(theme);
        }
    }, [theme]);

    return (
        <Box sx={{ flexGrow: 1 }}>
            <SkillsAppBar theme={theme}/>
            {theme === 'landing' ?
                <LandingPage setPage={(p) => setTheme(p)} />
                : <CenterGrid 
                    content={
                        id ?
                        <MMCard 
                            theme={theme}
                            setRandom={(color, dis) => setRandomCardOfColor(color, dis)}
                            cardId={parseInt(id)}
                        /> 
                        : <div></div>
                    }
                />
                
            }
        <Footer/>
        </Box>
    );
}
 
export default Page;